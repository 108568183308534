import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { Style } from "@tecnobit-srl/styles-manager";
import "@tecnobit-srl/ui-spinner/css";
function Spinner({ color }) {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Style, { style: "core-ui/spinner" }),
    /* @__PURE__ */ jsx("div", { className: "spinner", "data-color": color ?? "accent" })
  ] });
}
export {
  Spinner
};
